import { render, staticRenderFns } from "./email-editor-demo.vue?vue&type=template&id=24dbd93a&scoped=true&"
import script from "./email-editor-demo.vue?vue&type=script&lang=js&"
export * from "./email-editor-demo.vue?vue&type=script&lang=js&"
import style0 from "./email-editor-demo.vue?vue&type=style&index=0&id=24dbd93a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24dbd93a",
  null
  
)

export default component.exports